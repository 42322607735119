import revive_payload_client_4sVQNw7RlN from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/yash_devops/dagknows_nuxt/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/ubuntu/yash_devops/dagknows_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_select_Hligc0DBp2 from "/home/ubuntu/yash_devops/dagknows_nuxt/plugins/vue-select.js";
import cookie_accept_decline_pFjy8AFn9a from "/home/ubuntu/yash_devops/dagknows_nuxt/plugins/cookie-accept-decline.js";
import quill_editor_client_4SCKY6swlq from "/home/ubuntu/yash_devops/dagknows_nuxt/plugins/quill-editor.client.js";
import vue_good_table_B96A5JOtUv from "/home/ubuntu/yash_devops/dagknows_nuxt/plugins/vue-good-table.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  vue_select_Hligc0DBp2,
  cookie_accept_decline_pFjy8AFn9a,
  quill_editor_client_4SCKY6swlq,
  vue_good_table_B96A5JOtUv
]