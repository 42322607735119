import { default as change_45passwordnrbGMmcAGPMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/change-password.vue?macro=true";
import { default as _91id_93VpNQ9i1LXdMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/cli-sessions/[id].vue?macro=true";
import { default as _91id_93OMKSIbLkKAMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/deleted-tasks/[id].vue?macro=true";
import { default as edit_45user_45profilezMfaqQEY7eMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/edit-user-profile.vue?macro=true";
import { default as indexxIfMSVGGs5Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/index.vue?macro=true";
import { default as my_45draftsKpbvP6ieDmMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/my-drafts.vue?macro=true";
import { default as my_45runbooksHvbZLKZ3BZMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/my-runbooks.vue?macro=true";
import { default as downloadlJn4psSVHyMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/download.vue?macro=true";
import { default as interactive_45demo9IKpnY3JGwMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/interactive-demo.vue?macro=true";
import { default as jobsJgJywHC8R5Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/jobs.vue?macro=true";
import { default as messageWrbjxBcknLMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/message.vue?macro=true";
import { default as readmeY4xdf4ZkbXMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/readme.vue?macro=true";
import { default as setupMFArglNukClKYMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/setupMFA.vue?macro=true";
import { default as signupm1EB0RVfxbMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/signup.vue?macro=true";
import { default as user_45activities4lQDb680n4Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-activities.vue?macro=true";
import { default as user_45managementuuqkxV1MEIMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-management.vue?macro=true";
import { default as user_45preferenceshECAFO4XRSMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-preferences.vue?macro=true";
import { default as verifyMFAoPsjgoAFXRMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/verifyMFA.vue?macro=true";
import { default as privacy_45policyYv6vzipL3NMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/privacy-policy.vue?macro=true";
import { default as shared_45with_45mewf88xCvDQIMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/shared-with-me.vue?macro=true";
import { default as task_45create_45aiXREaO3iKBBMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/task-create-ai.vue?macro=true";
import { default as task_45creatennimN0Nj8rMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/task-create.vue?macro=true";
import { default as _91id_935lPOUn9nO1Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/tasks/[id].vue?macro=true";
import { default as terms_45of_45use9A2mWwbiZHMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/terms-of-use.vue?macro=true";
import { default as testJ3kVaeR2sKMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/test.vue?macro=true";
import { default as vLoginRedirectIjVJ4f2HyrMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/vLoginRedirect.vue?macro=true";
import { default as vintegrationsLRz6hMhsP8Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/vintegrations.vue?macro=true";
import { default as vloginwkudtbVnG9Meta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/vlogin.vue?macro=true";
import { default as vlogoutbSThJIdSpSMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/vlogout.vue?macro=true";
import { default as vsettingsLXMAzWpPpvMeta } from "/home/ubuntu/yash_devops/dagknows_nuxt/pages/vsettings.vue?macro=true";
export default [
  {
    name: change_45passwordnrbGMmcAGPMeta?.name ?? "change-password",
    path: change_45passwordnrbGMmcAGPMeta?.path ?? "/change-password",
    meta: change_45passwordnrbGMmcAGPMeta || {},
    alias: change_45passwordnrbGMmcAGPMeta?.alias || [],
    redirect: change_45passwordnrbGMmcAGPMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VpNQ9i1LXdMeta?.name ?? "cli-sessions-id",
    path: _91id_93VpNQ9i1LXdMeta?.path ?? "/cli-sessions/:id()",
    meta: _91id_93VpNQ9i1LXdMeta || {},
    alias: _91id_93VpNQ9i1LXdMeta?.alias || [],
    redirect: _91id_93VpNQ9i1LXdMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/cli-sessions/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OMKSIbLkKAMeta?.name ?? "deleted-tasks-id",
    path: _91id_93OMKSIbLkKAMeta?.path ?? "/deleted-tasks/:id()",
    meta: _91id_93OMKSIbLkKAMeta || {},
    alias: _91id_93OMKSIbLkKAMeta?.alias || [],
    redirect: _91id_93OMKSIbLkKAMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/deleted-tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: edit_45user_45profilezMfaqQEY7eMeta?.name ?? "edit-user-profile",
    path: edit_45user_45profilezMfaqQEY7eMeta?.path ?? "/edit-user-profile",
    meta: edit_45user_45profilezMfaqQEY7eMeta || {},
    alias: edit_45user_45profilezMfaqQEY7eMeta?.alias || [],
    redirect: edit_45user_45profilezMfaqQEY7eMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/edit-user-profile.vue").then(m => m.default || m)
  },
  {
    name: indexxIfMSVGGs5Meta?.name ?? "index",
    path: indexxIfMSVGGs5Meta?.path ?? "/",
    meta: indexxIfMSVGGs5Meta || {},
    alias: indexxIfMSVGGs5Meta?.alias || [],
    redirect: indexxIfMSVGGs5Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: my_45draftsKpbvP6ieDmMeta?.name ?? "my-drafts",
    path: my_45draftsKpbvP6ieDmMeta?.path ?? "/my-drafts",
    meta: my_45draftsKpbvP6ieDmMeta || {},
    alias: my_45draftsKpbvP6ieDmMeta?.alias || [],
    redirect: my_45draftsKpbvP6ieDmMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/my-drafts.vue").then(m => m.default || m)
  },
  {
    name: my_45runbooksHvbZLKZ3BZMeta?.name ?? "my-runbooks",
    path: my_45runbooksHvbZLKZ3BZMeta?.path ?? "/my-runbooks",
    meta: my_45runbooksHvbZLKZ3BZMeta || {},
    alias: my_45runbooksHvbZLKZ3BZMeta?.alias || [],
    redirect: my_45runbooksHvbZLKZ3BZMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/my-runbooks.vue").then(m => m.default || m)
  },
  {
    name: downloadlJn4psSVHyMeta?.name ?? "n-download",
    path: downloadlJn4psSVHyMeta?.path ?? "/n/download",
    meta: downloadlJn4psSVHyMeta || {},
    alias: downloadlJn4psSVHyMeta?.alias || [],
    redirect: downloadlJn4psSVHyMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/download.vue").then(m => m.default || m)
  },
  {
    name: interactive_45demo9IKpnY3JGwMeta?.name ?? "n-interactive-demo",
    path: interactive_45demo9IKpnY3JGwMeta?.path ?? "/n/interactive-demo",
    meta: interactive_45demo9IKpnY3JGwMeta || {},
    alias: interactive_45demo9IKpnY3JGwMeta?.alias || [],
    redirect: interactive_45demo9IKpnY3JGwMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/interactive-demo.vue").then(m => m.default || m)
  },
  {
    name: jobsJgJywHC8R5Meta?.name ?? "n-jobs",
    path: jobsJgJywHC8R5Meta?.path ?? "/n/jobs",
    meta: jobsJgJywHC8R5Meta || {},
    alias: jobsJgJywHC8R5Meta?.alias || [],
    redirect: jobsJgJywHC8R5Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/jobs.vue").then(m => m.default || m)
  },
  {
    name: messageWrbjxBcknLMeta?.name ?? "n-message",
    path: messageWrbjxBcknLMeta?.path ?? "/n/message",
    meta: messageWrbjxBcknLMeta || {},
    alias: messageWrbjxBcknLMeta?.alias || [],
    redirect: messageWrbjxBcknLMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/message.vue").then(m => m.default || m)
  },
  {
    name: readmeY4xdf4ZkbXMeta?.name ?? "n-readme",
    path: readmeY4xdf4ZkbXMeta?.path ?? "/n/readme",
    meta: readmeY4xdf4ZkbXMeta || {},
    alias: readmeY4xdf4ZkbXMeta?.alias || [],
    redirect: readmeY4xdf4ZkbXMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/readme.vue").then(m => m.default || m)
  },
  {
    name: setupMFArglNukClKYMeta?.name ?? "n-setupMFA",
    path: setupMFArglNukClKYMeta?.path ?? "/n/setupMFA",
    meta: setupMFArglNukClKYMeta || {},
    alias: setupMFArglNukClKYMeta?.alias || [],
    redirect: setupMFArglNukClKYMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/setupMFA.vue").then(m => m.default || m)
  },
  {
    name: signupm1EB0RVfxbMeta?.name ?? "n-signup",
    path: signupm1EB0RVfxbMeta?.path ?? "/n/signup",
    meta: signupm1EB0RVfxbMeta || {},
    alias: signupm1EB0RVfxbMeta?.alias || [],
    redirect: signupm1EB0RVfxbMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/signup.vue").then(m => m.default || m)
  },
  {
    name: user_45activities4lQDb680n4Meta?.name ?? "n-user-activities",
    path: user_45activities4lQDb680n4Meta?.path ?? "/n/user-activities",
    meta: user_45activities4lQDb680n4Meta || {},
    alias: user_45activities4lQDb680n4Meta?.alias || [],
    redirect: user_45activities4lQDb680n4Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-activities.vue").then(m => m.default || m)
  },
  {
    name: user_45managementuuqkxV1MEIMeta?.name ?? "n-user-management",
    path: user_45managementuuqkxV1MEIMeta?.path ?? "/n/user-management",
    meta: user_45managementuuqkxV1MEIMeta || {},
    alias: user_45managementuuqkxV1MEIMeta?.alias || [],
    redirect: user_45managementuuqkxV1MEIMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-management.vue").then(m => m.default || m)
  },
  {
    name: user_45preferenceshECAFO4XRSMeta?.name ?? "n-user-preferences",
    path: user_45preferenceshECAFO4XRSMeta?.path ?? "/n/user-preferences",
    meta: user_45preferenceshECAFO4XRSMeta || {},
    alias: user_45preferenceshECAFO4XRSMeta?.alias || [],
    redirect: user_45preferenceshECAFO4XRSMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/user-preferences.vue").then(m => m.default || m)
  },
  {
    name: verifyMFAoPsjgoAFXRMeta?.name ?? "n-verifyMFA",
    path: verifyMFAoPsjgoAFXRMeta?.path ?? "/n/verifyMFA",
    meta: verifyMFAoPsjgoAFXRMeta || {},
    alias: verifyMFAoPsjgoAFXRMeta?.alias || [],
    redirect: verifyMFAoPsjgoAFXRMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/n/verifyMFA.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyYv6vzipL3NMeta?.name ?? "privacy-policy",
    path: privacy_45policyYv6vzipL3NMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyYv6vzipL3NMeta || {},
    alias: privacy_45policyYv6vzipL3NMeta?.alias || [],
    redirect: privacy_45policyYv6vzipL3NMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: shared_45with_45mewf88xCvDQIMeta?.name ?? "shared-with-me",
    path: shared_45with_45mewf88xCvDQIMeta?.path ?? "/shared-with-me",
    meta: shared_45with_45mewf88xCvDQIMeta || {},
    alias: shared_45with_45mewf88xCvDQIMeta?.alias || [],
    redirect: shared_45with_45mewf88xCvDQIMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/shared-with-me.vue").then(m => m.default || m)
  },
  {
    name: task_45create_45aiXREaO3iKBBMeta?.name ?? "task-create-ai",
    path: task_45create_45aiXREaO3iKBBMeta?.path ?? "/task-create-ai",
    meta: task_45create_45aiXREaO3iKBBMeta || {},
    alias: task_45create_45aiXREaO3iKBBMeta?.alias || [],
    redirect: task_45create_45aiXREaO3iKBBMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/task-create-ai.vue").then(m => m.default || m)
  },
  {
    name: task_45creatennimN0Nj8rMeta?.name ?? "task-create",
    path: task_45creatennimN0Nj8rMeta?.path ?? "/task-create",
    meta: task_45creatennimN0Nj8rMeta || {},
    alias: task_45creatennimN0Nj8rMeta?.alias || [],
    redirect: task_45creatennimN0Nj8rMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/task-create.vue").then(m => m.default || m)
  },
  {
    name: _91id_935lPOUn9nO1Meta?.name ?? "tasks-id",
    path: _91id_935lPOUn9nO1Meta?.path ?? "/tasks/:id()",
    meta: _91id_935lPOUn9nO1Meta || {},
    alias: _91id_935lPOUn9nO1Meta?.alias || [],
    redirect: _91id_935lPOUn9nO1Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45use9A2mWwbiZHMeta?.name ?? "terms-of-use",
    path: terms_45of_45use9A2mWwbiZHMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45use9A2mWwbiZHMeta || {},
    alias: terms_45of_45use9A2mWwbiZHMeta?.alias || [],
    redirect: terms_45of_45use9A2mWwbiZHMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: testJ3kVaeR2sKMeta?.name ?? "test",
    path: testJ3kVaeR2sKMeta?.path ?? "/test",
    meta: testJ3kVaeR2sKMeta || {},
    alias: testJ3kVaeR2sKMeta?.alias || [],
    redirect: testJ3kVaeR2sKMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/test.vue").then(m => m.default || m)
  },
  {
    name: vLoginRedirectIjVJ4f2HyrMeta?.name ?? "vLoginRedirect",
    path: vLoginRedirectIjVJ4f2HyrMeta?.path ?? "/vLoginRedirect",
    meta: vLoginRedirectIjVJ4f2HyrMeta || {},
    alias: vLoginRedirectIjVJ4f2HyrMeta?.alias || [],
    redirect: vLoginRedirectIjVJ4f2HyrMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/vLoginRedirect.vue").then(m => m.default || m)
  },
  {
    name: vintegrationsLRz6hMhsP8Meta?.name ?? "vintegrations",
    path: vintegrationsLRz6hMhsP8Meta?.path ?? "/vintegrations",
    meta: vintegrationsLRz6hMhsP8Meta || {},
    alias: vintegrationsLRz6hMhsP8Meta?.alias || [],
    redirect: vintegrationsLRz6hMhsP8Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/vintegrations.vue").then(m => m.default || m)
  },
  {
    name: vloginwkudtbVnG9Meta?.name ?? "vlogin",
    path: vloginwkudtbVnG9Meta?.path ?? "/vlogin",
    meta: vloginwkudtbVnG9Meta || {},
    alias: vloginwkudtbVnG9Meta?.alias || [],
    redirect: vloginwkudtbVnG9Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/vlogin.vue").then(m => m.default || m)
  },
  {
    name: vlogoutbSThJIdSpSMeta?.name ?? "vlogout",
    path: vlogoutbSThJIdSpSMeta?.path ?? "/vlogout",
    meta: vlogoutbSThJIdSpSMeta || {},
    alias: vlogoutbSThJIdSpSMeta?.alias || [],
    redirect: vlogoutbSThJIdSpSMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/vlogout.vue").then(m => m.default || m)
  },
  {
    name: vsettingsLXMAzWpPpvMeta?.name ?? "vsettings",
    path: vsettingsLXMAzWpPpvMeta?.path ?? "/vsettings",
    meta: vsettingsLXMAzWpPpvMeta || {},
    alias: vsettingsLXMAzWpPpvMeta?.alias || [],
    redirect: vsettingsLXMAzWpPpvMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/yash_devops/dagknows_nuxt/pages/vsettings.vue").then(m => m.default || m)
  }
]